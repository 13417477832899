import React, {useRef, useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import css from './Stalker2Widget.module.scss';
import {findRangeForPulse} from '../../utils/rangeUtils';

export const DEFAULT_STALKER_WIDGET_RANGES = [
  {
    from: 0,
    to: 300,
    pulseColor: '#FFB400',
  },
];

const DustTexture =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/stalker/dust.png';

const LighterDirtTexture =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/stalker/lighter-hd.png';

const DarkerDirtTexture =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/stalker/darker-hd.png';

export type Stalker2WidgetProps = WidgetProps<
  {pulseColor?: string},
  {
    baseColor: string;
  }
>;

export const Stalker2Widget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: Stalker2WidgetProps) => {
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const baseColor = configuration.baseColor;
  const colorPalette = getColorPaletteForBase(baseColor);
  const [digits, setDigits] = useState<number[]>([]);
  let ranges = configuration.ranges || DEFAULT_STALKER_WIDGET_RANGES;

  let pulseColor = findRangeForPulse(ranges, pulse)?.pulseColor || '#FFB400';

  useEffect(() => {
    let images = [DustTexture, LighterDirtTexture, DarkerDirtTexture];

    // Preload images
    images.forEach((src: string) => {
      new Image().src = src;
    });
  }, []);

  const extractDigits = (num: number): (number | null)[] => {
    const numStr = String(num);

    if (numStr.length === 3) {
      return numStr.split('').map((digit) => parseInt(digit, 10));
    } else if (numStr.length === 2) {
      return [null, ...numStr.split('').map((digit) => parseInt(digit, 10))];
    } else if (numStr.length === 1) {
      return [null, null, parseInt(numStr, 10)];
    }

    return [null, null, null];
  };

  function getColorPaletteForBase(baseColor: string) {
    let colors: {[key: string]: string};

    switch (baseColor.toLowerCase()) {
      case 'green':
        colors = {
          baseUp: '#7E733F',
          baseDown: '#51440A',
          embossUp: '#302805',
          embossDown: '#A7964A',
          textUp: '#FFF2D5',
          textDown: '#5F5C3A',
          baseFilterURL: 'url(#green_base_filter)',
          embossFilterURL: 'url(#green_emboss_filter)',
        };
        break;

      case 'red':
        colors = {
          baseUp: '#F43925',
          baseDown: '#C5200F',
          embossUp: '#AF1000',
          embossDown: '#FF6556',
          textUp: '#FFC0C0',
          textDown: '#FFC0C0',
          baseFilterURL: 'url(#red_base_filter)',
          embossFilterURL: 'url(#red_emboss_filter)',
        };
        break;

      case 'black':
        colors = {
          baseUp: '#1C1C25',
          baseDown: '#0F0F14',
          embossUp: '#0C0C10',
          embossDown: '#373742',
          textUp: '#9B9BA8',
          textDown: '#9B9BA8',
          baseFilterURL: 'url(#black_base_filter)',
          embossFilterURL: 'url(#black_emboss_filter)',
        };
        break;
    }

    return colors;
  }

  useEffect(() => {
    setDigits(extractDigits(pulse));
  }, [pulse]);

  return (
    <div
      style={{
        position: 'relative',
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        top: '0',
        left: '0',
      }}
    >
      <svg
        viewBox="0 0 200 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: 'auto',
          display: 'block',
        }}
      >
        <g filter={colorPalette.baseFilterURL}>
          <path
            d="M0 20C0 8.9543 8.95431 0 20 0H180C191.046 0 200 8.95431 200 20V168C200 179.046 191.046 188 180 188H20C8.95431 188 0 179.046 0 168V20Z"
            fill="url(#paint0_linear_4609_2)"
          />
        </g>
        <g filter={colorPalette.embossFilterURL}>
          <path
            d="M10 20C10 14.4772 14.4772 10 20 10H180C185.523 10 190 14.4772 190 20V124C190 129.523 185.523 134 180 134H20C14.4772 134 10 129.523 10 124V20Z"
            fill="url(#paint1_linear_4609_2)"
          />
        </g>
        <text
          className={css.text2}
          x="10%"
          y="83%"
          dominantBaseline="middle"
          textAnchor="start"
          fill="url(#paint0_radial_2701_308)"
          filter="url(#filter0_di_2701_308)"
        >
          <tspan x="10%" y="83%">
            ПУЛЬСОМЕТР
          </tspan>
          <tspan x="10%" dy="1.2em">
            ІНДИВІДУАЛЬНИЙ ДУБ-2К
          </tspan>
        </text>
        <defs>
          <clipPath id="clip">
            <rect x="0" y="0" width="200" height="188" rx="20" ry="20" />
          </clipPath>
        </defs>
        <image
          href={DarkerDirtTexture}
          style={{clipPath: 'url(#clip)', mixBlendMode: 'multiply'}}
          width="100%"
          height="100%"
        />
        <image
          href={LighterDirtTexture}
          style={{clipPath: 'url(#clip)', mixBlendMode: 'plus-lighter'}}
          width="100%"
          height="100%"
        />
        <g filter="url(#filter2_i_4609_2)">
          <path
            d="M20 24C20 21.7909 21.7909 20 24 20H176C178.209 20 180 21.7909 180 24V120C180 122.209 178.209 124 176 124H24C21.7909 124 20 122.209 20 120V24Z"
            fill="url(#paint2_linear_4609_2)"
          />
        </g>
        {pulse <= 90 ? (
          <>
            <g filter="url(#filter0_di_4615_3)">
              <circle
                cx="40"
                cy="108"
                r="5"
                fill="url(#paint1_radial_4615_3)"
              />
              <animate
                attributeName="opacity"
                values="1; 0; 1;"
                dur={`1200ms`}
                repeatCount="indefinite"
              />
            </g>
            <circle cx="58" cy="108" r="5" fill="#281111" />
          </>
        ) : pulse > 90 && pulse < 120 ? (
          <>
            <circle cx="58" cy="108" r="5" fill="#281111" />
            <g filter="url(#filter0_di_4615_3)">
              <circle
                cx="40"
                cy="108"
                r="5"
                fill="url(#paint1_radial_4615_3)"
              />
            </g>
            <g filter="url(#filter3_di_4609_2)">
              <circle
                cx="58"
                cy="108"
                r="5"
                fill="url(#paint3_radial_4609_2)"
              />
              <animate
                attributeName="opacity"
                values="1; 0; 1;"
                dur={`900ms`}
                repeatCount="indefinite"
              />
            </g>
          </>
        ) : (
          <>
            <g filter="url(#filter3_di_4609_2)">
              <circle
                cx="58"
                cy="108"
                r="5"
                fill="url(#paint3_radial_4609_2)"
              />
            </g>
            <g filter="url(#filter0_di_4615_3)">
              <circle
                cx="40"
                cy="108"
                r="5"
                fill="url(#paint1_radial_4615_3)"
              />
            </g>
          </>
        )}
        <text
          className={css.pulse}
          x="37%"
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill="#262632"
          // filter="url(#text-shadow)"
        >
          8
        </text>
        <text
          className={css.pulse}
          x={digits[0] == 1 ? '38.9%' : '37%'}
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill={pulseColor}
          // filter="url(#text-shadow)"
        >
          {shouldShowEmptyState ? emptyStateComponent : digits[0]}
        </text>
        <text
          className={css.pulse}
          x="58.5%"
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill="#262632"
          // filter="url(#text-shadow)"
        >
          8
        </text>
        <text
          className={css.pulse}
          x={digits[1] == 1 ? '60.4%' : '58.5%'}
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill={pulseColor}
          // filter="url(#text-shadow)"
        >
          {shouldShowEmptyState ? emptyStateComponent : digits[1]}
        </text>
        <text
          className={css.pulse}
          x="80%"
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill="#262632"
          // filter="url(#text-shadow)"
        >
          8
        </text>
        <text
          className={css.pulse}
          x={digits[2] == 1 ? '81.9%' : '80%'}
          y="47%"
          dominantBaseline="right"
          textAnchor="end"
          fill={pulseColor}
          // filter="url(#text-shadow)"
        >
          {shouldShowEmptyState ? emptyStateComponent : digits[2]}
        </text>
        <text
          className={css.text}
          x="84%"
          y="59%"
          dominantBaseline="right"
          textAnchor="end"
          fill="#FFE9E7"
        >
          УДАРІВ/ХВ
        </text>

        <defs>
          <clipPath id="clip_display">
            <rect x="10%" y="11%" width="160" height="103" rx="4" ry="4" />
          </clipPath>
        </defs>
        <image
          href={DustTexture}
          x="3%"
          y="-13%"
          height="200"
          width="200"
          style={{mixBlendMode: 'plus-lighter', clipPath: 'url(#clip_display)'}}
        />
        <defs>
          <filter id="filter0_di_2701_308" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_778_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_778_2"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_778_2"
            />
          </filter>

          <radialGradient
            id="paint0_radial_2701_308"
            cx="40%"
            cy="50%"
            r="110%"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0%" stop-color={colorPalette.textUp} />
            <stop offset="100%" stop-color={colorPalette.textDown} />
          </radialGradient>
        </defs>

        <defs>
          <filter
            id="green_base_filter"
            x="0"
            y="-1"
            width="200"
            height="191"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.855225 0 0 0 0 0.788341 0 0 0 0 0.484794 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_2777_261"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.117578 0 0 0 0 0.0981563 0 0 0 0 0.00687403 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_2777_261"
              result="effect2_innerShadow_2777_261"
            />
          </filter>

          <filter
            id="red_base_filter"
            x="0"
            y="-2"
            width="200"
            height="192"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.420999 0 0 0 0 0.361344 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_2777_249"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.176904 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_2777_249"
              result="effect2_innerShadow_2777_249"
            />
          </filter>

          <filter
            id="black_base_filter"
            x="0"
            y="-1"
            width="200"
            height="191"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.328952 0 0 0 0 0.335132 0 0 0 0 0.421647 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_2777_273"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_2777_273"
              result="effect2_innerShadow_2777_273"
            />
          </filter>

          <filter
            id="green_emboss_filter"
            x="7"
            y="8"
            width="186"
            height="130"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_778_2"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.670459 0 0 0 0 0.604392 0 0 0 0 0.29947 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_778_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_778_2"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.818278 0 0 0 0 0.77153 0 0 0 0 0.545585 0 0 0 0.56 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_778_2"
            />
          </filter>

          <filter
            id="red_emboss_filter"
            x="7"
            y="8"
            width="186"
            height="130"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_778_2"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.916683 0 0 0 0 0.610272 0 0 0 0 0.620894 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_778_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_778_2"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.621273 0 0 0 0 0.627585 0 0 0 0.56 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_778_2"
            />
          </filter>

          <filter
            id="black_emboss_filter"
            x="7"
            y="8"
            width="186"
            height="130"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_778_2"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.288165 0 0 0 0 0.288165 0 0 0 0 0.332373 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_778_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_778_2"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.408361 0 0 0 0 0.408361 0 0 0 0 0.504264 0 0 0 0.56 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_778_2"
            />
          </filter>

          <filter
            id="filter2_i_4609_2"
            x="20"
            y="20"
            width="160"
            height="104"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_4609_2"
            />
          </filter>
          <filter
            id="filter3_di_4609_2"
            x="42"
            y="92"
            width="32"
            height="32"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.145098 0 0 0 0 0.145098 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4609_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4609_2"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect2_innerShadow_4609_2"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.866895 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_4609_2"
            />
          </filter>
          <filter
            id="filter0_di_4615_3"
            x="24"
            y="92"
            width="32"
            height="32"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.219608 0 0 0 0 0.729412 0 0 0 0 0.247059 0 0 0 0.9 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4615_3"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4615_3"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect2_innerShadow_4615_3"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0683873 0 0 0 0 0.418831 0 0 0 0 0.0872574 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_4615_3"
            />
          </filter>
          <radialGradient
            id="paint1_radial_4615_3"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(40 107) rotate(90) scale(6)"
          >
            <stop stop-color="#81FF96" />
            <stop offset="1" stop-color="#03CB00" />
          </radialGradient>
          <linearGradient
            id="paint0_linear_4609_2"
            x1="87"
            y1="2.16838e-06"
            x2="138.447"
            y2="188.56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color={colorPalette.baseUp} />
            <stop offset="1" stop-color={colorPalette.baseDown} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4609_2"
            x1="81.5909"
            y1="10"
            x2="100.375"
            y2="133.943"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color={colorPalette.embossUp} />
            <stop offset="1" stop-color={colorPalette.embossDown} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4609_2"
            x1="65"
            y1="20"
            x2="100"
            y2="124"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0C0C13" />
            <stop offset="1" stop-color="#14141C" />
          </linearGradient>
          <radialGradient
            id="paint3_radial_4609_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(58 107) rotate(90) scale(6)"
          >
            <stop stop-color="#FF8181" />
            <stop offset="1" stop-color="#EF1600" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};
